import React, {useEffect, useState}  from "react";
import { Link } from 'gatsby';
import { Button, Hero, ExternalIcon } from "components/ui";
import { WhatsNew, MoreResources, CurrentTopics, LatestWebinars, AboutNCSACW } from "components/containers/Homepage/index";
import HomepageHeroDesktop from "assets/images/hero-home-1200x426-ICWA-toolkit.jpg";
import HomepageHeroTablet from "assets/images/hero-home-tablet-1040x425-ICWA-toolkit.jpg";
import HomepageHeroDesktopOct2024 from "assets/images/hero-home-1200x426-oct2024.jpg";
import HomepageHeroTabletOct2024 from "assets/images/hero-home-tablet-1040x425-oct2024.jpg";
import { DateTime } from "luxon";

// const heroContent={
//   title: "Safety & Risk Practice Guides",
//   link: "topics/safety-and-risk/safety-risk-guides-series"
// }
const heroContent={
  title: "ICWA Active Efforts Support Toolkit",
  link: "topics/american-indian-alaska-native/ICWA-active-efforts-series"
}
const heroContentOct2024={
  title: "Supporting AI/AN Families",
  link: "https://cffutures.zoom.us/webinar/register/WN_IEy3ZOtoTqiXj7bjeWTm9A#/registration"
}

const Home = () => {
  const [currentDate, setCurrentDate] = useState(false);
  const timeZone = "America/New_York"
  // November 7, 2024 at 2:30pm
  const year = 2024;
  const month = 11;
  const day = 7;
  const hour = 14;
  const minute = 30;
  const updateHeroDate = DateTime.fromObject({year:year,month:month,day:day,hour:hour,minute:minute},{zone:timeZone});

  useEffect(()=>{
    setCurrentDate(DateTime.now().setZone(timeZone));
  }, [])
  return(
    <>
        <Hero
        desktopImg={currentDate > updateHeroDate ? HomepageHeroDesktop:HomepageHeroDesktopOct2024}
        tabletImg={currentDate > updateHeroDate ? HomepageHeroTablet:HomepageHeroTabletOct2024}
        color={"#363B50"}
        textColor="#fff"
        headingColor="#fff"
        isHomeHero={true}
        >
          <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${currentDate > updateHeroDate ? heroContent.title : heroContentOct2024.title}`}>{currentDate > updateHeroDate ? heroContent.title : heroContentOct2024.title}</h1>
          {currentDate > updateHeroDate ? <p>Helps child welfare workers, substance use treatment staff, court personnel, attorneys, and healthcare professionals support American Indian and Alaskan Native (AI/AN) families affected by substance use</p>:<p>This upcoming webinar will highlight tools for systems to deliver culturally appropriate services for American Indian/Alaska Native families including the new <Link to="/topics/american-indian-alaska-native/ICWA-active-efforts-series">ICWA Active Efforts Support Toolkit</Link>!</p>}
          {currentDate > updateHeroDate ?
            <Button
            color="usa-button--secondary"
            labelText={`Open ${heroContent.title} page`}
            link={heroContent.link}
            >
              View Series
            </Button>
            :
            <div className="display-flex">
              <a 
              href="https://cffutures.zoom.us/webinar/register/WN_IEy3ZOtoTqiXj7bjeWTm9A#/registration"
              className="usa-button usa-button--secondary"
              aria-label={`Register for ${heroContentOct2024.title} webinar`}
              target="_blank" 
              rel="noreferrer"
              >
                Register
              </a>
              <ExternalIcon iconColor="white" />
            </div>
          }
        </Hero>
        <div className="light-gray-bg">
          <div className="grid-container">
            <div className="grid-row">
              <WhatsNew />
              <LatestWebinars />
            </div>
          </div>
        </div>
        <AboutNCSACW />
        <div className="blue-bg padding-y-5">
          <CurrentTopics />
        </div>
      <MoreResources />
    </>
  )
}

export default Home;
