import React from "react";
import {Link} from "gatsby";
import {FlagCard, Button} from "components/ui";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {useStrapiLatestWebinarsData, useStrapiLatestWebinarsSeriesData} from "hooks";
import VideoPlaceholderImage from "assets/images/thumbnail-video-generic.jpeg";

const LatestWebinars = () => {
  const { allStrapiVideo } = useStrapiLatestWebinarsData();
  const { allStrapiSerie } = useStrapiLatestWebinarsSeriesData();
  const latestWebinarsData=[...allStrapiVideo.nodes,...allStrapiSerie.nodes];
  const CardContent = styled.div`
   .usa-card__media{
      .gatsby-image-wrapper {
          width: 100%;
          img {
            width: 100%;
          }
      }
    @media(max-width: 40rem){
      display: block;
    }
  }
  @media(max-width: 55rem){
  .view-more{
    text-align: center;
  }
}
   .usa-card__img{
    border-radius: 0;
  }
  p{
    color: #2A5254;
  }
@media(min-width: 640px){
  .usa-card__img img{
      height: 7rem;
      width: 100%;
      @media (max-width: 880px) and (min-width: 640px){
      width: 100%;
      height: 100%;
    }
  }
  .usa-card{
    margin-bottom: 0;
  }
  .usa-card__media{
    width: 12rem;
    @media (max-width: 880px) and (min-width: 640px){
      width: 15rem;
      height: 8.5rem;
    }
  }
  .usa-card__body{
    height: 9rem;
    margin-left: 11rem;
    margin-right: 0;
    padding-top: 1rem;
    padding-right: 0;
    @media (max-width: 880px) and (min-width: 640px){
      margin-left: 12rem;
    }
  }
}
@media(min-width: 880px){
  h2, .primary-button, .usa-card__media{
    margin-left: -0.5rem;
  }
  .usa-card__body{
    padding-top: 0;
    margin-left: 11rem;
  }
}
.view-more{
  @media(min-width:1024px){
    margin-top: 6px;
}
  @media(min-width:963px) and (max-width:1024px){
    margin-top: 10px;
  }
  @media (min-width:880px) and (max-width:963px){
    margin-top: 33px;
  }
  @media (max-width:880px){
    margin-top: 10px;
  }
}
`;
const sortWebinars=webinars=>{
  const webinarsOrder=["Peer Support: A Path to Hope and Family Recovery","Cannabis Webinar Series","How to Use Data for Change: Improving Outcomes for Families Affected by Substance Use"]
  let sortedWebinars=webinars.sort((currentWebinar,webinar)=>webinarsOrder.indexOf(currentWebinar.title)-webinarsOrder.indexOf(webinar.title))
  return sortedWebinars;
}
  return (
      <div className="tablet-lg:grid-col-6 tablet:grid-col-12 margin-y-5 tablet-lg:border-base-lighter tablet-lg:border-left tablet-lg:padding-left-7 latest-webinars__container">
        <CardContent>
        <h2>Latest Webinars</h2>
        <div className="usa-card-group">
          {latestWebinarsData && sortWebinars(latestWebinarsData)?.map(webinar=>{
            return (
              <div className="display-flex" key={`webinar-${webinar.strapi_id}`}>
              <Link to={webinar.slug === "cannabis-webinar-series" ? `topics/cannabis/${webinar.slug}` : `training/videos-and-webinars/${webinar?.slug}`}>
                  <FlagCard
                  key={`latest-webinar-${webinar.strapi_id}`}
                  grid="grid-col-12 margin-top-2"
                  >
                    <FlagCard.Media>
                      <div className="usa-card__img">
                      {webinar?.thumbnail ?
                        <GatsbyImage
                        image={getImage(webinar?.thumbnail?.localFile)}
                        alt={webinar?.thumbnail?.alternativeText ?? ""}
                        />
                      :
                        <img
                        src={VideoPlaceholderImage}
                        alt=""
                        />
                      }
                      </div>
                    </FlagCard.Media>
                    <FlagCard.Body>
                      <p>
                      {webinar?.title?.substring(0,84)}{webinar?.title?.length > 84 && "..."}
                      </p>
                    </FlagCard.Body>
                  </FlagCard>
                  </Link>
                  <div className="flex-align-self-center">
                  </div>
                </div>
              )})}
          </div>
          <div className="view-more">
          <Button
          color="primary-button"
          size="padding-x-5"
          labelText="View more webinars"
          link="training/videos-and-webinars"
          >
            View More
          </Button>
        </div>
        </CardContent>
      </div>
    )
}

export default LatestWebinars;
